<template>
  <div
    v-if="tags.length > 0"
    class="o-seo-tags"
    :class="{ 'at-end': atEnd , 'at-start': atStart}"
  >
    <MSectionHeader
      :title="title"
      :subtitle="subtitle"
      title-level="2"
      :link-text="linkText"
      :link-href="linkHref"
    />
    <div
      ref="scrollRefContainer"
      @wheel.prevent="handleWheel"
      @scroll="handleScroll"
    >
      <div v-if="loading" class="o-seo-tags__skeleton">
        <div v-for="n in 8" :key="n" />
      </div>
      <template v-else-if="firstBlock.length">
        <div>
          <AChip
            v-for="(item, index) in firstBlock"
            :key="index"
            :path="item.section?.path?.value ?? ''"
            @click.prevent="resetScrollAndPush(item.section?.path?.value ?? '', $event)"
          >
            {{ item.title }}
          </AChip>
        </div>
        <div v-if="secondBlock.length">
          <AChip
            v-for="(item, index) in secondBlock"
            :key="index"
            :path="item.section?.path?.value ?? ''"
            @click.prevent="resetScrollAndPush(item.section?.path?.value ?? '', $event)"
          >
            {{ item.title }}
          </AChip>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import './SeoTags.css'
import { ref } from 'vue'
import { useNavigate } from '@/composables/useNavigate'
import { useCatalogStore } from '@/stores/catalog'

import type { InterestingSection } from '@/modules/nuxt-api/models/InterestingSection'

import AChip from '@/components/atoms/Chip/AChip.vue'
import MSectionHeader from '@/components/molecules/SectionHeader/MSectionHeader.vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  linkText: {
    type: String,
    default: ''
  },
  linkHref: {
    type: String,
    default: ''
  },
  cacheHref: {
    type: String,
    default: ''
  },
  href: {
    type: String,
    default: ''
  },
  loading: Boolean
})

const { blankPush } = useNavigate()
const { getInterestingSection } = useCatalogStore()

const scrollRefContainer = ref<any>()
const firstBlock = ref<InterestingSection[]>([])
const secondBlock = ref<InterestingSection[]>([])
const atEnd = ref(false)
const atStart = ref(false)

const tags = await getInterestingSection(props.href).catch(() => [])

const calcTagsDataCount = () => {
  if (tags.length > 4) {
    const endIdx = Math.ceil(tags.length / 2)
    firstBlock.value = tags.slice(0, endIdx)
    secondBlock.value = tags.slice(endIdx)
  } else {
    firstBlock.value = tags
    secondBlock.value = []
  }
}

function resetScrollAndPush (to: string, event: any) {
  window.scrollTo(0, 0)

  blankPush(to, event)
}

const handleWheel = (event: WheelEvent) => {
  const container = scrollRefContainer.value
  if (container) {
    const scrollAmount = Math.abs(event.deltaX) > Math.abs(event.deltaY) ? event.deltaX : event.deltaY
    container.scrollLeft += scrollAmount
  }
}

const handleScroll = (event: any) => {
  const target = event.target
  atEnd.value = (target.scrollWidth - Math.ceil(target.scrollLeft)) <= target.clientWidth
  atStart.value = target.scrollLeft >= 8
}

calcTagsDataCount()
</script>
